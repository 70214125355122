import React, { useState, useRef } from "react";
import HouseCard from "./HouseCard";
import { useDraggable } from "react-use-draggable-scroll";
import styles from "./houseSlider.module.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import mirissa from "./mirissa.jpg"
import colombo from "./colombo.jpg"
import ella from "./ella.jpg"
import kandy from "./templeofthetoothrelic.jpg"


// Define local data
const localData = [
    {
        id: 1,
        MainPhoto:kandy,
        Name: "Temple of the Tooth Relic",
        Bed: "5.8 Ratings",
    
        Size: "4 Tours",
    },
    {
        id: 2,
        MainPhoto: colombo,
        Name: "Colombo",
        Bed: "4.8 Ratings",
       
        Size: "9 Tours",
    },
    {
        id: 2,
        MainPhoto: ella,
        Name: "Ella",
        Bed: "3.8 Ratings",
      
        Size: "4 Tours",
    },  {
        id: 2,
        MainPhoto: mirissa,
        Name: "Mirissa",
        Bed: "5.8 Ratings",
   
        Size: "6 Tours",
    },
    // Add more objects as needed
];

function HouseSlider() {
    const [data] = useState(localData); // Use local data instead of Firebase
    const imgWrapperRef = useRef(null);
    const { events } = useDraggable(imgWrapperRef); // Changed to imgWrapperRef
    const navigate = useNavigate();

    const handleMoreInfo = (itemData) => {
        // Navigate to the "More Info" route with the item data
        navigate("/propery-details", { state: { itemData } });
    };

    const scrollToLeft = () => {
        if (imgWrapperRef.current) {
            imgWrapperRef.current.scrollBy({
                left: -600,
                behavior: "smooth",
            });
        }
    };

    const scrollToRight = () => {
        if (imgWrapperRef.current) {
            imgWrapperRef.current.scrollBy({
                left: 600,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="house-slider-container" style={{ position: "relative" }}>
            <div
                className="flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide"
                id={styles.scrollContainer}
                {...events}
                ref={imgWrapperRef}
            >
                {data.map((itemData) => (
                    <HouseCard
                        key={itemData.id}
                        imageSrc={itemData.MainPhoto}
                        address={itemData.Name}
                        bedroomsCount={itemData.Bed}
                      
                        sqFt={itemData.Size}
                        onMoreInfo={() => handleMoreInfo(itemData)}
                    />
                ))}
            </div>
            <button className={styles.leftArrow} onClick={scrollToLeft}>
                <LeftOutlined className={styles.leftArrowIcon} />
            </button>
            <button className={styles.rightArrow} onClick={scrollToRight}>
                <RightOutlined className={styles.rightArrowIcon} />
            </button>
        </div>
    );
}

export default HouseSlider;
