import React from 'react';
import Slider from 'react-slick';
import './TopRatedTours.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image1 from './sl0101.jpg'; // Replace with actual image paths
import image2 from './sl0101.jpg';
import image3 from './sl0101.jpg';
import image4 from './sl0101.jpg';

const tourData = [
  {
    title: '09 Nights 10 Days Sri Lanka Tour',
    type: 'Honeymoon Tours',
    description: 'A 10-day honeymoon tour in Sri Lanka will allow you to explore various regions of the country, including stunning beaches, cultural sites, wildlife reserves, and scenic landscapes. Here\'s a...',
    rating: 5,
    image: image1,
  },
  {
    title: '03 Nights 04 Days Sri Lanka Tour',
    type: 'Adventure Tours',
    description: 'Sri Lanka is a remarkable country for its tropical paradise and the picturesque and charming cities... The golden sandy beaches are the most attracted and the crystal clear water also gives an unforgettable memory.',
    rating: 5,
    image: image2,
  },
  {
    title: '06 Nights 07 Days Sri Lanka Tour',
    type: 'Honeymoon Tours',
    description: 'This 7-day honeymoon tour in Sri Lanka will allow you to explore more of the country\'s diverse landscapes, cultural sites, and romantic experiences. Here\'s a suggested itinerary for your...',
    rating: 5,
    image: image3,
  },
  {
    title: '04 Nights 05 Days Sri Lanka Tour',
    type: 'Famous Tours',
    description: 'Far off places like Bentota, Baye, and Colombo are part of this tour. Experience the amazing beaches and the beauty of Sri Lanka...',
    rating: 5,
    image: image4,
  },
];

function TopRatedTours() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className="top-rated-tours">
      <h2>Our Top Rated Tours</h2>
      <p>Book Your dream Semi - Luxury car for your wedding day with us now</p>
      <Slider {...settings}>
        {tourData.map((tour, index) => (
          <div key={index} className="tour-card">
            <img src={tour.image} alt={tour.title} />
            <div className="tour-info">
              <h3>{tour.title}</h3>
              <p>{tour.type}</p>
              <p>{tour.description}</p>
              <div className="rating">
                <span>{'★'.repeat(tour.rating)}</span>
                <span>{tour.rating}</span>
              </div>
              <a href="#">View More</a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default TopRatedTours;
