import React from 'react';
import HowItWorks from './HowItWorks';
import WeddingCarBooking from './WeddingCarBooking';
import Luxury from './Luxury';
import SemiLuxury from './SemiLuxury';

function Wedding() {
  return (
    <div className="Wedding">
        <WeddingCarBooking/>
        
      <HowItWorks />
      <Luxury/>
      <SemiLuxury/>
    </div>
  );
}

export default Wedding;
