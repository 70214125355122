import React from 'react';
import './Footer.css';
import logo from "../../Assets/logo.png";


const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-item">
                    <img src={logo} alt="VIP Travels Logo" className="footer-logo" />
                    <p>Our cab service offers the most dependable and comfortable rides in town. Whether you need a ride to the airport, a night out on the town, or a quick trip across town, our professional drivers will get you where you need to go safely and efficiently.</p>
                    <div className="footer-search">
                        <input type="text" placeholder="Search..." />
                        <button type="button">🔔</button>
                    </div>
                </div>
                
                <div className="footer-item">
                    <h3>Our Services</h3>
                    <ul>
                        <li>cab services</li>
                        <li>car rental</li>
                        <li>wedding car</li>
                        <li>air ticketing</li>
                        <li>tours</li>
                        <li>transports</li>
                    </ul>
                </div>
                <div className="footer-item">
                    <h3>Quick Links</h3>
                    <ul>
                        <li>refund policy</li>
                        <li>privacy policy</li>
                        <li>terms & users</li>
                        <li>driver policy</li>
                        <li>become a driver</li>
                    </ul>
                </div>
                <div className="footer-item">
                    <h3>Contacts</h3>
                    <p>Wennappuwa Branch<br />No.301, Shopping Complex, Wennappuwa</p>
                    <p>Negombo Branch<br />2nd Kurana, Colombo road, Negombo</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyrights 2024 | <span className="developer">Developed By Uptick Solutions</span></p>
            </div>
        </footer>
    );
};

export default Footer;