// src/components/WeddingCarBooking.js
import React from "react";
import "./WeddingCarBooking.css";
import weddingimage from "./Layer 1.png";

const WeddingCarBooking = () => {
  return (
    <div className="booking-container">
      <div className="illustration">
        <img src={weddingimage} alt="Wedding illustration" />
      </div>
      <div className="booking-form">
        <h2>Book a Car Now</h2>
        <form>
          <label>
            <p>Select a Type</p>
            <select>
              <option>Select</option>
            </select>
          </label>
          <label>
            <p> Select a Vehicle</p>
            <select>
              <option>Select</option>
            </select>
          </label>
          <label>
            <p> Rent Hours</p>
            <select>
              <option>Select</option>
            </select>
          </label>
          <label>
            <p> Pickup Time</p>
            <input type="datetime-local" />
          </label>
          <label>
            <p>City</p>

            <input type="text" placeholder="Enter Passenger City" />
          </label>
          <label>
            <p> Name</p>
            <input type="text" placeholder="Enter Passenger Name" />
          </label>
          <label>
            <p> Mobile Number</p>

            <input type="text" placeholder="Enter Passenger Mobile Number" />
          </label>
          <div className="price-and-button">
            {/* <span>Price:</span> */}
            <button type="submit">Book Now</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WeddingCarBooking;
