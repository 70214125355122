import React from "react";
import DestinationCarousel from "../../Components/DestinationCarousel/DestinationCarousel";
import HouseSlider from "../../Components/TravelSlider/HouseSlider";
import DreamHolidaySection from "../../Components/DreamHolidaySection/DreamHolidaySection";
import TopRatedTours from "../../Components/TopRatedTours/TopRatedTours";

const Tours = () => {
  return (
    <div className="tourspage">
      <DreamHolidaySection/>
      <TopRatedTours/>

      <DestinationCarousel/>
      <HouseSlider/>
  
    </div>
  );
};

export default Tours;
