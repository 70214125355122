import React from 'react';
import './DreamHolidaySection.css';
import backgroundImage from './wp6828550.jpg'; // Adjust the path as needed

function DreamHolidaySection() {
  return (
    <div className="dream-holiday-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="overlay">
        <h1>Plan Your Dream Holiday</h1>
        <p>
          Each day on this island promises new experiences, discoveries, and life-long memories. With so much to do, 
          let us help you with these itineraries created just for you!
        </p>
      </div>
    </div>
  );
}

export default DreamHolidaySection;
