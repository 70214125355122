import React from 'react';
import './Luxury.css';
import BenzEclass from "./BenzEclass.png";
import BMW320d from "./BMW320d.png";
import BMW720d from "./BMW720d.png";
import Chrysler from "./Chrysler.png";

const cars = [
  {
    name: 'BMW 320d',
    passengers: 3,
    color: 'White',
    priceRange: 'LKR 18000 - 36000',
    hourlyRate: 'LKR 1000 Hourly',
    rating: 4.8,
    imageUrl:BMW320d, // Update with correct path
  },
  {
    name: 'Benz E-Class',
    passengers: 3,
    color: 'White',
    priceRange: 'LKR 18000 - 36000',
    hourlyRate: 'LKR 1000 Hourly',
    rating: 4.4,
    imageUrl: BenzEclass, // Update with correct path
  },
  {
    name: 'BMW 720d',
    passengers: 3,
    color: 'White',
    priceRange: 'LKR 18000 - 36000',
    hourlyRate: 'LKR 1000 Hourly',
    rating: 4.7,
    imageUrl: BMW720d, // Update with correct path
  },
  {
    name: 'Chrysler',
    passengers: 3,
    color: 'White',
    priceRange: 'LKR 18000 - 36000',
    hourlyRate: 'LKR 1000 Hourly',
    rating: 4.5,
    imageUrl: Chrysler, // Update with correct path
  },
]; 

const Luxury = () => {
  return (
    <div className="luxury">
      <h2>Luxury</h2>
      <p>Book Your dream Luxury car for your wedding day with us now</p>
      <div className="car-cards">
        {cars.map((car, index) => (
          <div className="car-card" key={index}>
            <img src={car.imageUrl} alt={car.name} className="car-image" />
            <h3>{car.name}</h3>
            <p>Passenger: {car.passengers}</p>
            <p>Color: {car.color}</p>
            <p>{car.priceRange}</p>
            <p>{car.hourlyRate}</p>
            <div className="rating">⭐ {car.rating}</div>
            <button className="book-button">Book Now</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Luxury;
