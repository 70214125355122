import React from "react";
import "./DestinationSlider.css";

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h2>How it works ?</h2>
      <p className="subtitle">Easy steps to get you started</p>
      <p className="note">
        Note - In this process you have to agree with our terms and conditions
        and privacy policy and refunding policy
      </p>
      <div className="steps-container">
        <div className="step-number">1</div>

        <div className="step">
          <div className="step-content">
            <h3>Select your Vehicle</h3>
            <p>
              Select the vehicle what you want to hire, and what is affordable
              for you.
            </p>
          </div>
        </div>
        <div className="step-number">2</div>

        <div className="step">
          <div className="step-content">
            <h3>Select Rental Date Range</h3>
            <p>
              Select rental date range. If you want to rent for a month, select
              monthly rental.
            </p>
          </div>
        </div>
        <div className="step-number">3</div>

        <div className="step">
          <div className="step-content">
            <h3>Submit your documents</h3>
            <p>Submit your documents as requested by VIP Travels.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
