import React, { useState } from 'react';
import './Header.css';
import logo from "../../Assets/logo.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={`header ${isOpen ? 'active' : ''}`}>
      <div className="logo">
        <img src={logo} alt="VIP Travels" />
      </div>
      <nav className="nav">
        <ul>
          <li><a href="/" className="active">Home</a></li>
          <li><a href="/car-rental">Car Rental</a></li>
          <li><a href="/wedding-cars">Wedding Cars</a></li>
          <li><a href="/tours">Tours</a></li>
          {/* <li><a href="/air-ticketing">Air Ticketing</a></li> */}
        </ul>
      </nav>
      <button className="call-now">Call Now</button>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export default Header;
