import React from "react";
import "./Services.css";
import cabIcon from "./icon 1.png";
import carRentalIcon from "./rent 1.png";
import weddingCarsIcon from "./wedding 1.png";
import toursIcon from "./tourism 1.png";
import transportIcon from "./taxi 1.png";
import airTicketingIcon from "./airtickets 1.png";

const services = [
  {
    title: "Cab Service",
    description:
      "VIP Travels' cab service offers a fleet of well-maintained vehicles and professional drivers, providing safe and comfortable transportation for any occasion.",
    icon: cabIcon,
  },
  {
    title: "Car Rental",
    description:
      "VIP Travels' car rental service offers flexible rental options and a range of vehicles to suit your specific needs, from budget cars to spacious vans.",
    icon: carRentalIcon,
  },
  {
    title: "Wedding Cars",
    description:
      "Make your special day unforgettable with VIP Travels' wedding car service, offering a range of elegant and stylish vehicles and professional drivers.",
    icon: weddingCarsIcon,
  },
  {
    title: "Tours",
    description:
      "VIP Travels' tour service offers a variety of packages to explore the beauty and culture of our destination, led by knowledgeable and friendly tour guides.",
    icon: toursIcon,
  },
  {
    title: "Transport",
    description:
      "VIP Travels' transport service provides reliable and efficient transportation for corporate events, conferences, and group travel needs.",
    icon: transportIcon,
  },
  {
    title: "Air Ticketing",
    description:
      "VIP Travels' air ticketing service provides hassle-free and convenient booking for flights, with access to the latest deals and travel packages.",
    icon: airTicketingIcon,
  },
];

const Services = () => {
  return (
    <div className="services">
      <h2>Our Services</h2>
      <p className="services-description">
        VIP Travels offers a variety of high-quality transportation services,
        including cab, car rental, wedding car, transport, tour, and air
        ticketing services. Book with us for a safe, comfortable, and
        unforgettable travel experience.
      </p>
      <div className="container">
        <div className="services-grid">
          {services.map((service, index) => (
            <div key={index} className="service-item">
              <div className="service-icon-container">
                <img src={service.icon} alt="hel" className="service-icon" />
              </div>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
