// src/components/CarCard.js

import React, { useState } from "react";
import "./CarSlider.css";
import vanImage from "./van (1).png"; // replace with actual image paths
import carImage from "./mini.png";
import sedanImage from "./sedan (1).png";
import miniVanImage from "./mini-van.png";

const cars = [
  {
    title: "Van",
    description: "Our taxi service has big, nice vans for groups or a lot of things. Our vans are fun to ride in because they are cool, have comfortable seats, and come with entertainment systems. Our vans are great for group trips, family vacations, and business events. Our skilled drivers will get you where you need to go quickly and safely. Having a van service makes driving and parking",
    image: vanImage
  },
  {
    title: "Mini Car",
    description: "They are cheap and easy to use. Our taxi service has small cars for city trips and tourists who are traveling alone. Our little cars are cozy and have air conditioning. Our drivers will get you there quickly and with a smile. Reserve a small car today!",
    image: carImage
  },
  {
    title: "Sedan Car",
    description: "Sedans look finest. We offer luxury cars for business trips, rides to and from the airport, and special events. Our sedans are nice to ride in because they have air conditioning, leather seats, and the most up-to-date entertainment systems. Our drivers are skilled, so you'll get there on time. Tourists can relax in our autos.",
    image: sedanImage
  },
  {
    title: "Mini Van",
    description: "Need a taxi that fits a family? Service for minivans! Our small cars can fit 8 people and their bags. The vans are small, but they have air conditioning and entertainment systems. Our drivers will get you there quickly and with a smile. Our small vans are great for road trips, getting to and from the airport, and group trips. Reserve a comfortable minivan today.",
    image: miniVanImage
  }
];

const CarCard = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % cars.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + cars.length) % cars.length);
  };

  const currentCar = cars[currentSlide];

  return (
    <div className="car-slider">
      <button className="arrows left-arrow" onClick={handlePrev}>&larr;</button>
      <div className="car-image">
        <img src={currentCar.image} alt={currentCar.title} />
      </div>
      <div className="car-details">
        <h2>{currentCar.title}</h2>
        <p>{currentCar.description}</p>
        {/* <div className="rating">
          <span className="stars">★ ★ ★ ★ ★</span>
          <span>Five star Rating</span>
        </div> */}
        <div className="thumbnails">
          {cars.map((car, index) => (
            <img
              key={index}
              src={car.image}
              alt={car.title}
              className={currentSlide === index ? "active" : ""}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
      </div>
      <button className="arrows right-arrow" onClick={handleNext}>&rarr;</button>
    </div>
  );
};

export default CarCard;
