import React from 'react';
import './SemiLuxury.css';
import HondaGrace from "./HondaGrace.png";
import HondaVezel from "./HondaVezel.png";
import ToyotaAxio from "./ToyotaAxio.png";
import ToyotaPremio from "./ToyotaPremio.png";

const cars = [
  {
    name: 'Honda Grace',
    passengers: 3,
    color: 'White',
    priceRange: 'LKR 8000 - 24000',
    hourlyRate: 'LKR 1000 Hourly',
    rating: 3.9,
    imageUrl: HondaGrace, // Update with correct path
  },
  {
    name: 'Toyota Premio',
    passengers: 3,
    color: 'White',
    priceRange: 'LKR 8000 - 24000',
    hourlyRate: 'LKR 1000 Hourly',
    rating: 4.6,
    imageUrl: ToyotaPremio, // Update with correct path
  },
  {
    name: 'Toyota Axio',
    passengers: 3,
    color: 'White',
    priceRange: 'LKR 8000 - 24000',
    hourlyRate: 'LKR 1000 Hourly',
    rating: 5.0,
    imageUrl: ToyotaAxio, // Update with correct
  },
  {
    name: 'Vezel',
    passengers: 3,
    color: 'White',
    priceRange: 'LKR 8000 - 24000',
    hourlyRate: 'LKR 1000 Hourly',
    rating: 4.5,
    imageUrl: HondaVezel, // Update with correct path
  },
];

const SemiLuxury = () => {
  return (
    <div className="semi-luxury">
      <h2>Semi Luxury</h2>
      <p>Book Your dream Semi Luxury car for your wedding day with us now</p>
      <div className="car-cards">
        {cars.map((car, index) => (
          <div className="car-card" key={index}>
            <img src={car.imageUrl} alt={car.name} className="car-image" />
            <h3>{car.name}</h3>
            <p>Passenger: {car.passengers}</p>
            <p>Color: {car.color}</p>
            <p>{car.priceRange}</p>
            <p>{car.hourlyRate}</p>
            <div className="rating">⭐ {car.rating}</div>
            <button className="book-button">Book Now</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SemiLuxury;
