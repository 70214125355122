import React from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h2>How it works ?</h2>
      <p>Easy steps to get you started</p>
      <p>Note - In this process you have to agree with our terms and conditions and privacy policy and refunding policy</p>
      <div className="steps">
        <div className="step">
          <div className="number">1</div>
          <h3>Select your Vehicle</h3>
          <p>Select the vehicle what you want to hire. and what affordable for you.</p>
        </div>
        <div className="step">
          <div className="number">2</div>
          <h3>Select Rental Date Range</h3>
          <p>Select rental date range. if you want to rent for month select monthly rental</p>
        </div>
        <div className="step">
          <div className="number">3</div>
          <h3>Submit your documents</h3>
          <p>Submit your document which requested by VIP Travels</p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
