import React from "react";
import CabBooking from "../../Components/Booking/Booking";
import Services from "../../Components/Services/Services";
import Slider from "../../Components/Slider/Slider";
import CarCarousel from "../../Components/CarCarousel/CarCarousel";

import RecentlyAdded from "../../Components/RecentlyAdded/RecentlyAdded";


const Home = () => {
  return (
    <div className="home">
      {/* <CabBooking /> */}

    

      {/* <RecentlyAdded/> */}
      

      <div>
        <h1>Ride in Style with Our Reliable Cab Service</h1>
      </div>
      <Slider />

      <Services />

      {/* <CarCarousel/> */}
    </div>
  );
};

export default Home;
