import React from "react";
import PropTypes from "prop-types";
import styles from "./houseCard.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

HouseCard.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    bedroomsCount: PropTypes.number.isRequired,
  
    sqFt: PropTypes.number.isRequired,
    onMoreInfo: PropTypes.func.isRequired,
};


function HouseCard({
    imageSrc,
    address,
    bedroomsCount,

    sqFt,
    onMoreInfo,
}) {
    const handleClick = () => {
        onMoreInfo();
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.imgContainer}>
                <img className={styles.houseImage} src={imageSrc} alt={address} />
            </div>
            <div className={styles.bottomContainer}>
                <p className={styles.houseAddress}>{address}</p>
                <div className={styles.featuresContainer}>
                    <p>Features</p>
                    <div className={styles.features}>
                        <p id="BedRoomsCount">{`${bedroomsCount} `}</p>
                        <span>|</span>
             
                        <p id="large">{`${sqFt} `}</p>
                    </div>
                </div>
                <button
                    className={styles.moreDetailsButton}
                    onClick={handleClick} // Call handleClick function
                >
          <button type="submit" className="book-now-button">Book Now</button>
                </button>
            </div>
        </div>
    );
}

export default HouseCard;
